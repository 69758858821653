import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { ManagerMasterGroupsMasterGroupIdCalendarGetParams } from '@/store/types/schema'
import {
  CalendarLargeResource, IManagerCalendarFilter,
  ManagerMasterGroupsMasterGroupIdCalendarGetRequest,
} from '@/store/types'

/**
 * Календарь наставника
 * - todo
 */

@Module({
  dynamic: true,
  name: 'MentorCalendar',
  namespaced: true,
  store,
})
class MentorCalendar extends VuexModule {
  // ---------------------------- Mentor Calendar ---------------------------- >>
  calendar: CalendarLargeResource | null = null
  calendarFilter: IManagerCalendarFilter = {
    endAt: '',
    exerciseStatus: null,
    lessonStatus: null,
    startAt: '',
  }

  @Mutation
  setCalendar(payload: CalendarLargeResource) {
    this.calendar = payload
  }

  @Mutation
  setCalendarFilter(payload: IManagerCalendarFilter) {
    this.calendarFilter = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchCalendar(payload: { masterGroupId: number, params: ManagerMasterGroupsMasterGroupIdCalendarGetParams }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdCalendarGetRequest(payload.masterGroupId, payload.params)
    this.setCalendar(data)
    return data
  }
}

const MentorCalendarModule = getModule(MentorCalendar)

export default MentorCalendarModule
