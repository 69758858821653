import { DateTime } from 'luxon'
import { Component } from 'vue-property-decorator'

import AuthModule from '@/store/modules/auth'
import DictionaryModule from '@/store/modules/dictionary'
import { CourseType, NameValueResource } from '@/store/types/schema'
import MasterCalendarModule from '@/store/modules/master/calendar'
import MasterEducationModule from '@/store/modules/master/education'
import MentorCalendarModule from '@/store/modules/mentor/calendar'
import SystemModule from '@/store/modules/system'
import {
  CalendarLargeItemResource,
  ManagerMasterGroupsMasterGroupIdCalendarGetParams,
  MasterEducationMasterGroupIdCalendarGetParams,
} from '@/store/types'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import { formatDate, parseDateToMilliseconds } from '@/utils/functions'

enum ScheduleMarks {
  LESSONS = 'LESSONS',
  EXERCISES = 'EXERCISES',
  PERSONALS = 'PERSONALS',
  WEBINARS = 'WEBINARS',
  MATERIALS = 'MATERIALS',
}

enum MenuActions {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
}

export enum EventType {
  LESSON = 'lesson',
  EXERCISE = 'exercise',
  USEFUL_MATERIAL = 'useful_material',
}

@Component
export default class CalendarMixin extends MasterGroupMixin {
  private get currentTime() {
    return DictionaryModule.currentTime
  }

  /////////////////////////////////////
  // Note: Общие данные для календаря
  /////////////////////////////////////
  public get calendar() {
    if (SystemModule.interface === 'manager') {
      return MentorCalendarModule.calendar
    } else {
      return MasterCalendarModule.calendar
    }
  }

  /////////////////////////////////////
  // Note: Для "малого" календаря
  /////////////////////////////////////

  // Функция нужна для того, чтобы понимать есть ли домашки ещё в этот день,
  // в котором есть и персональный урок и домашки
  private _isAnyExserciseOnThisDay(exercise: CalendarLargeItemResource, dayInfo: [number, number, number]) {
    return this.calendar?.exercise.some(item => {
      if (this._isShouldBeMarked(item, dayInfo) && exercise.exerciseAccessLevel !== item.exerciseAccessLevel) {
        return true
      }
      return false
    })

  }

  private _isAnyLessonOnThisDay(lesson: CalendarLargeItemResource, dayInfo: [number, number, number]) {
    return this.calendar?.lesson.some(item => {
      if (this._isShouldBeMarked(item, dayInfo) && lesson.isWebinar !== item.isWebinar) {
        return true
      }
      return false
    })
  }

  public handleEvents(date: string) {
    const [year, month, day] = date.split('-').map(item => +item)
    const eventsClasses: string[] = []

    let isLessonShouldBeMarked = false

    if (this.calendar) {
      this.calendar.lesson.some(item => {
        isLessonShouldBeMarked = this._isShouldBeMarked(item, [day, month, year])

        if (isLessonShouldBeMarked) {
          /*if (item.isWebinar && !eventsClasses.includes('webinars'))
            eventsClasses.push('webinars')*/
          if (!item.isWebinar && !eventsClasses.includes('lessons'))
            eventsClasses.push('lessons')
        }
      })

      this.calendar.exercise.some(item => {
        isLessonShouldBeMarked = this._isShouldBeMarked(item, [day, month, year])

        if (isLessonShouldBeMarked) {
          if (!eventsClasses.includes('exercises')) {
            eventsClasses.push('exercises')
          }
        }
      })

      this.calendar.usefulMaterial.some(item => {
        isLessonShouldBeMarked = this._isShouldBeMarked(item, [day, month, year])

        if (isLessonShouldBeMarked) {
          if (!eventsClasses.includes('material'))
            eventsClasses.push('material')
        }
      })
      return eventsClasses
    }

    else return false
  }

  private _isShouldBeMarked(item: CalendarLargeItemResource, date: number[]) {
    const _day = DateTime.fromSQL(item.type === EventType.USEFUL_MATERIAL ? item.spendingAt : item.deadlineAt).toJSDate().getDate()
    const _month = DateTime.fromSQL(item.type === EventType.USEFUL_MATERIAL ? item.spendingAt : item.deadlineAt).toJSDate().getMonth() + 1
    const _year = DateTime.fromSQL(item.type === EventType.USEFUL_MATERIAL ? item.spendingAt : item.deadlineAt).toJSDate().getFullYear()
    return Boolean(date[0] === _day && date[1] === _month && date[2] === _year)
  }

  public handleUpdatePickerDate(date: string): ManagerMasterGroupsMasterGroupIdCalendarGetParams | MasterEducationMasterGroupIdCalendarGetParams {
    const year = +date.substring(0, 4)
    const month = +date.substring(5, 8)

    const endAt = (new Date(year, month, 1)).toISOString().substring(0, 10)
    const startAt = date.toString() + '-01'

    return { endAt, startAt }
  }

  /////////////////////////////////////
  // Note: Для "большого" календаря
  /////////////////////////////////////
  private get startAt() {
    return SystemModule.interface === 'master' ? MasterCalendarModule.calendarFilter.startAt : MentorCalendarModule.calendarFilter.startAt
  }
  // Этот геттер нужен для запоминания фильтра
  private get filterMonth() {
    if (!this.startAt) {
      return this.calendarToday
    }
    const [ year, month, day ] = this.startAt.split('-')

    if (day === '01') {
      return `${year}-${month}-01`
    }

    if (month === '12') {
      return `${+year + 1}-01-01`
    }

    return `${year}-${+month + 1}-01`
  }

  private focus = this.filterMonth
  public currentChosenOption = this.menu[0]

  protected get scheduleMarks(): NameValueResource[] {
    const baseScheduleMarks = [
      { name: 'Урок', value: ScheduleMarks.LESSONS },
      { name: 'Задание', value: ScheduleMarks.EXERCISES },
      { name: 'Полезно', value: ScheduleMarks.MATERIALS },
      { name: 'Завершено', value: 'ended' },
    ]
    return baseScheduleMarks
  }

  public get menu () {
    const list = [
      { name: 'Месяц', value: MenuActions.MONTH },
    ]

    if (this.$vuetify.breakpoint.name === 'xs') {
      list.push({ name: 'День', value: MenuActions.DAY })
    } else {
      list.push({ name: 'Неделя', value: MenuActions.WEEK })
    }

    return list
  }

  public get weekdays() {
    return [1, 2, 3, 4, 5, 6, 0]
  }

  public get dateRaw() {
    return DictionaryModule.currentTimeRaw
  }

  public get calendarToday() {
    return this.dateRaw.slice(0, 10)
  }

  public get exerciseStatuses() {
    return DictionaryModule.exerciseStatusesFilter
  }

  public get lessonStatuses() {
    return DictionaryModule.lessonStatusesFilter
  }

  public formatHours(date: any) {
    return date.time
  }

  public fetchCalendar(masterGroupID: number, filter: MasterEducationMasterGroupIdCalendarGetParams | ManagerMasterGroupsMasterGroupIdCalendarGetParams) {
    if (SystemModule.interface === 'master') {
      return MasterCalendarModule.fetchCalendar({ masterGroupId: masterGroupID, params: filter })
    } else {
      return MentorCalendarModule.fetchCalendar({ masterGroupId: masterGroupID, params: filter })
    }
  }

  public get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  public get largeEvents() {
    const events: any[] = []

    if (this.calendar) {
      this.calendar.lesson.forEach((item: CalendarLargeItemResource) => {
        const start = formatDate(item.spendingAt, 'yyyy-MM-dd HH:mm:ss', this.isLocalTimezone)
        const end = formatDate(item.deadlineAt, 'yyyy-MM-dd HH:mm:ss', this.isLocalTimezone)

        const result: any = {
          //color: item.isWebinar ? 'orange darken-4' : 'orange desaturate-1', // цвет заднего фона
          color: `teal lighten-2 ${item.isPassed ? 'ended' : ''}`,
          end,
          endTime: formatDate(end, 'HH:mm'),
          id: item.id,
          isWebinar: item.isWebinar,
          name: item.title,
          start,
          time: formatDate(start, 'HH:mm'),
          type: item.type,
        }

        events.push(result)
      })

      this.calendar.exercise.filter((item: CalendarLargeItemResource) => SystemModule.interface === 'manager' ? true : item.tasks && item.tasks.length).forEach((item: CalendarLargeItemResource) => {
        const start = formatDate(item.deadlineAt, 'yyyy-MM-dd HH:mm:ss', this.isLocalTimezone)

        const result: any = {
          //color: (item.exerciseAccessLevel as any).value === ExerciseAccessLevel.PERSONAL ? 'blue darken-1' : item.spendingAt > this.dateRaw ? 'grey' : 'purple', // цвет заднего фона
          color: parseDateToMilliseconds(item.spendingAt) > this.currentTime && SystemModule.interface === 'master' ? 'grey' : `orange desaturate-2 ${item.isPassed ? 'ended' : ''}`,
          id: item.id,
          name: item.tasks && item.tasks.length ? item.tasks[0].title : item.title || 'Нет заданий',
          spendingAt: item.spendingAt,
          start,
          taskUuid: item.tasks && item.tasks.length ? item.tasks[0].uuid : '',
          time: formatDate(start, 'HH:mm'),
          type: item.type,
          uuid: item.uuid,
        }

        events.push(result)
      })

      this.calendar.usefulMaterial?.forEach((item: CalendarLargeItemResource) => {
        const start = formatDate(item.spendingAt, 'yyyy-MM-dd HH:mm:ss', this.isLocalTimezone).replace(/\d{2}:\d{2}:\d{2}/, '01:00:00')

        const result: any = {
          color: 'blue lighten-2',
          id: item.id,
          name: item.title,
          spendingAt: start,
          start,
          type: item.type,
        }

        events.push(result)
      })
    }
    return events
  }

  // Метод клика по событию календаря
  // Если isLargeEvent === true, тогда клик произшёл на большом календаре и нужно вытащить объект event из события
  // иначе просто работать с тем что есть, т.к. клик происходит из событий на дашборде
  public handleCalendarEventClick(eventInfo: any, isLargeEvent: boolean, masterGroupId?: number) {
    const event = isLargeEvent ? eventInfo.event : eventInfo
    if (!isLargeEvent) {
      event.type = eventInfo.typeEvent
    }
    // дата начала дз
    const accessAt = event.accessAt ? parseDateToMilliseconds(event.accessAt) : isLargeEvent ? parseDateToMilliseconds(event.spendingAt) : null
    const dateRaw = this.currentTime

    if (event.type === EventType.LESSON) {
      if (SystemModule.interface === 'manager') {
        this.$router.push({ name: 'manager.education.lessons.item', params: { groupID: (masterGroupId as number).toString(), lessonID: event.id } })
          .catch(() => {return})
      } else {
        this.$router.push({ name: 'master.lessons.item', params: { groupID: MasterEducationModule.currentMasterGroupID.toString(), lessonID: event.id } })
          .catch(() => {return})
      }
    } else if (event.type === EventType.EXERCISE) {
      if (SystemModule.interface === 'manager') {
        event.taskUuid ?
          this.$router.push({ name: this.currentMasterGroup.course.type.value === CourseType.SPECIAL ? 'manager.education.exercises.quickStart' : 'manager.education.exercises.item.masterList', params: { exerciseUUID: event.uuid, groupID: (masterGroupId as number).toString(), taskUUID: event.taskUuid } }).catch(() => {return}) :
          this.$router.push({ name: 'manager.education.exercises.item.edit', params: { exerciseUUID: event.uuid, groupID: this.currentMasterGroupID.toString() } })
            .catch(() => {return})
      } else if (SystemModule.interface === 'master' && accessAt && accessAt > dateRaw) {
        return
      } else {
        this.$router.push({ name: 'master.exercises.item', params: { exerciseUUID: event.uuid } })
          .catch(() => {return})
      }
    } else if (event.type === EventType.USEFUL_MATERIAL) {
      if (SystemModule.interface === 'master') {
        this.$router.push({ name: 'master.depository.item.materials', params: { courseID: this.currentMasterGroup.course.id.toString() }, query: { endDate: formatDate(event.start, 'yyyy-MM-dd'), startDate: formatDate(event.start, 'yyyy-MM-dd') } })
          .catch(() => {return})
      } else {
        this.$router.push({ name: 'manager.bank.depository.courses.item.materials.view', params: { courseID: this.currentMasterGroup.course.id.toString(), materialID: event.id.toString() } })
          .catch(() => {return})
      }
    }
  }

  public determinateClassMarks(value: ScheduleMarks) { // функция для стилизации отметок
    /*if (value === ScheduleMarks.EXERCISES) {
      return 'exercises'
    } else if (value === ScheduleMarks.LESSONS) {
      return 'lessons'
    } else if (value === ScheduleMarks.PERSONALS) {
      return 'personals'
    } else if (value === ScheduleMarks.WEBINARS) {
      return 'webinars'
    } else if (value === ScheduleMarks.MATERIALS) {
      return 'materials'
    }*/
    if (value === ScheduleMarks.EXERCISES) {
      return 'exercises'
    }
    if (value === ScheduleMarks.LESSONS) {
      return 'lessons'
    }
    /*if (value === ScheduleMarks.PERSONALS) {
      return 'personals'
    }
    if (value === ScheduleMarks.WEBINARS) {
      return 'webinars'
    }*/
    if (value === ScheduleMarks.MATERIALS) {
      return 'materials'
    }
    return 'ended'
  }

  public weekLabelDecider(date: string) {
    return DateTime.fromSQL(date, { zone: 'Europe/Moscow' }).weekdayShort
  }
}
